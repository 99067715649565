class State {
    constructor(initialState = {}) {
        this.state = { ...initialState };
        this.subscribers = [];
    }

    getState() {
        return { ...this.state };
    }

    setState(newState) {
        this.state = { ...this.state, ...newState };
        this.notifySubscribers();
    }

    subscribe(callback) {
        this.subscribers.push(callback);

        // 返回一个取消订阅的函数
        return () => {
            this.subscribers = this.subscribers.filter(subscriber => subscriber !== callback);
        };
    }

    notifySubscribers() {
        this.subscribers.forEach(subscriber => {
            subscriber(this.getState());
        });
    }
}

window.State = State;
