function download(filename, url) {
    try {
        const xhr = new XMLHttpRequest();
        xhr.open('get', url);
        xhr.responseType = 'blob';
        xhr.send();
        xhr.onload = function () {
            if (this.status === 200 || this.status === 304) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.response);
                fileReader.onload = function () {
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = this.result;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };
            }
        };
        xhr.onerror = function (err) {
            alert('Download Failed!');
        }
    } catch (e) {
        console.error('error', e);
    }
}

const toggles = document.querySelectorAll('[data-es-download]');

toggles.forEach((toggle) => {
    const name = toggle.dataset.esDownload || `ES-Download-File-${(+new Date()).toString(36)}`;
    const source = toggle.dataset.esSource;

    toggle.addEventListener('click', e => {
        e.stopPropagation();

        !!source && download(name, source);
    })
});

window.download = download;
