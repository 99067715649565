// 
// user.js
// Use this to write your custom JS
//

import { token_key, user_info_key, system_info_key } from '../config/localStorageConstant';

// 用户行为多维拆解页面的点击事件
function userBehaviorClick (title, e) {
    let elementById = document.getElementById('analysis-tab');
    let elementsByTagName = elementById.getElementsByTagName('a');
    elementsByTagName.forEach(el => {
        if (el.innerText.includes(title)) {
            e.style.background = 'linear-gradient(180deg, #BBD8FF 0%, #FFFFFF 100%)';
            el.children[0].children[2].setAttribute(
                'src',
                'https://es-center.entrolysis.com/center/official_web_image/Group163197.png',
            );
            el.children[0].children[1].className = 'userBehavior-list-blue';
        } else {
            el.children[0].style.background = 'linear-gradient(180deg, #F9FAFE 0%, #FFFFFF 100%)';
            el.children[0].children[2].setAttribute(
                'src',
                'https://es-center.entrolysis.com/center/official_web_image/Group163197-1.png',
            );
            el.children[0].children[1].className = 'userBehavior-list-black';
        }
    });
}

// 构建用户画像页面的鼠标移动事件
function buildUserPortrait_onmousemove (distance, url) {
    document.getElementById('move').style.top = distance + 'px';
    document.getElementById('back').style.top = distance + 'px';
    document.getElementById('left-img').setAttribute('src', url);
}

function hideElement (element) {
    if (element) {
        element.style.display = 'none';
    }
}

function showElement (element) {
    if (element) {
        // 登录按钮需要字体居中
        if (element.id === 'login-button') {
            element.style.display = 'flex';
        } else if (element.id === 'login-popover') {
            element.style.display = 'block';
            element.style.opacity = 1;
        } else {
            element.style.display = 'block';
        }
    }
};

// 清除localStorage中用户相关的信息
function clearUserLocalStorage () {
    localStorage.removeItem(token_key);
    localStorage.removeItem(user_info_key);
    localStorage.removeItem(system_info_key);

}

// 回显用户数据
function echoUserData () {

    const SKIP_LOGIN = window.SKIP_LOGIN;

    const userAvatar = document.getElementById('user-avatar');
    // 用于显示数据的元素
    // 我的项目
    const project = document.getElementById('project');
    // 头像数据
    const avatarText = document.getElementById('avatar-text');
    // 退出按钮
    const signOut = document.getElementById('sign-out');
    // 个人中心按钮
    const personalCenter = document.getElementById('personal-center');
    // 登录部分
    const loginBtn = document.getElementById('login-button');
    // 登录的下拉框
    const loginPopover = document.getElementById('login-popover');
    // 用户名称 头像旁边的回显
    const avatarName = document.getElementById('avatar-name');
    // 拥护名称 下拉框的回显
    const popoverName = document.getElementById('popover-name');
    //  token信息
    let token = localStorage.getItem(token_key);
    //  用户信息
    let userInfoStr = localStorage.getItem(user_info_key);
    //  关联的项目信息
    let systemInfoStr = localStorage.getItem(system_info_key);
    if (userInfoStr === null) {
        showElement(loginBtn);
        return;
    }
    // 解密
    let userInfo = AES_process({ data: userInfoStr, operation: 'decrypt' });

    let systemInfo = AES_process({ data: systemInfoStr, operation: 'decrypt' });
    let localAccount;

    hideElement(loginBtn);
    hideElement(loginPopover);
    if (userInfo) {
        showElement(loginPopover);
        userInfo = JSON.parse(userInfo);
        const { headImage, nickName, account } = userInfo;
        localAccount = account;
        if (headImage) {
            userAvatar.style.backgroundImage = `url(${headImage})`;
        } else {
            userAvatar.style.backgroundColor = `#3f89ec`;
            avatarText.textContent = nickName[0];
        }
        avatarName.textContent = nickName;
        popoverName.textContent = `Hi，${nickName}`;

        // 跳转所需加密参数
        const params = encodeURIComponent(AES_process({
            data: JSON.stringify({ token: token, account: localAccount }),
            operation: 'encrypt',
        }));

        // 登出的点击事件
        signOut && signOut.addEventListener('click', () => {
            clearUserLocalStorage();
            window.location.replace('/subpage/login.html');
        });

        // 个人中心的点击事件
        personalCenter && personalCenter.addEventListener('click', () => {
            window.open(`${SKIP_LOGIN}/manage/IPersonalCenter?ui=${params}`, '_blank');
        });

    } else {
        showElement(loginBtn);
        clearUserLocalStorage();
    }

    if (systemInfo) {
        // 透明度也得设置下
        showElement(project);

        // 跳转所需加密参数
        const params = encodeURIComponent(AES_process({
            data: JSON.stringify({ token: token, account: localAccount }),
            operation: 'encrypt',
        }));
        project && project.addEventListener('click', () => {
            window.open(`${SKIP_LOGIN}/manage/IProjectManage?ui=${params}`, '_blank');
        });
    } else {
        hideElement(project);
    }
}

window.userBehaviorClick = userBehaviorClick;
window.buildUserPortrait_onmousemove = buildUserPortrait_onmousemove;
window.echoUserData = echoUserData;
