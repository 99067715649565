const storage = window.sessionStorage;

const message = (handler, description = 'unknown error', title = 'Error') => {
    console.error(`[${handler}] ${title}: ${description}`);
}

const throwNotFound = () => {
    throw new Error(`source not found`);
}

const setStorage = (key, value) => {
    try {
        storage.setItem(key, JSON.stringify(value));
        return true;
    } catch (e) {
        message('setStorage', e);
    }
}

const getStorage = key => {
    try {
        return JSON.parse(storage.getItem(key));
    } catch (e) {
        message('getStorage', e);
    }
}

async function request(url, options = {}) {
    try {
        const { session, otherOptions } = options;

        let response;

        if (options.session) {
            const cache = getStorage(session);
            cache && (response = {
                ok: true,
                json: () => cache,
            })
        }

        !response && (response = await fetch(url, otherOptions));

        if (!response.ok) {
            throwNotFound();
        }

        const data = await response.json();
        setStorage(session, data);

        return data;
    } catch (error) {
        message('Request', error);
        throw error; // 重新抛出错误，以便调用者能够进一步处理
    }
}


window.request = request;
