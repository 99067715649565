import axios from 'axios'

const api = process.env.OFFICIAL_API

async function axiosRequest({ url, method, data, headers, responseType, cancelObj, onUploadProgress, onDownloadProgress }) {
    try {

        const response = await axios.request({
            url: `${api}/${url}`,
            method,
            data,
            headers,
            responseType,
            ...cancelObj,
            timeout: 120 * 1000,
            onUploadProgress: onUploadProgress,
            onDownloadProgress: onDownloadProgress
        })

        if (response.status !== 200) throw new Error(`网络错误, ${response.statusText}`)

        return response.data
    } catch (error) {
        console.log(`%c error`, 'color:#e1463d', error);
    }

}

window.axiosRequest = axiosRequest;