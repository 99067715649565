import CryptoJS from "crypto-js";

const AES_key = "official_mgr_AES"
const AES_iv = "official_mgr_eiv"

/**
 * 进行AES加密
 * @param content 需要进行加密的内容
 * @param key AES加密/解密的key
 * @param iv AES加密/解密的偏移量
 * @param operation 加密/解密
 * @returns 加密后的字符串
 */
function AES_process({ data, operation }) {
    try {
        const _key = CryptoJS.enc.Utf8.parse(AES_key);
        const _iv = CryptoJS.enc.Utf8.parse(AES_iv);

        if (operation === 'encrypt') {
            const encrypted = CryptoJS.AES.encrypt(data, _key, {
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            return encrypted.toString();
        } else if (operation === 'decrypt') {
            const decrypted = CryptoJS.AES.decrypt(data, _key, {
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            return CryptoJS.enc.Utf8.stringify(decrypted);
        } else {
            throw new Error('Invalid operation');
        }
    } catch (error) {
        console.error('AES process error:', error);
    }
}

window.AES_process = AES_process;